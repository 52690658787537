@import '_var.scss';

.picker {
    display: flex;
    flex-direction: column;
    padding: 8px;

    &__header {
        margin: 8px;
        margin-right: 2px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 24px;
        user-select: none;
    }

    &__part-name {
        color: $dark-cerulean;
    }
}
