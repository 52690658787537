@import '_var.scss';

.part-info-modal {
    &__more-icon {
        border-radius: 4px;
        height: 24px;
        width: 24px;
        border: none;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background $quick;
        cursor: pointer;
        outline: none;

        &:hover {
            background: $off-white;
        }
    }

    &__body {
        display: flex;
        user-select: initial;
    }

    &__left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-content: center;
        justify-items: center;
        pointer-events: none;
        padding-right: 8px;
    }

    &__value {
        font-family: $font-mono;
        font-size: $font-sm;
        text-align: center;
        border: 2px solid $off-white;
        padding: 4px 8px;
        border-radius: 4px;
        margin-top: 24px;
        pointer-events: initial;
        background: $white;
        z-index: 1;
    }

    &__right {
        padding-left: 16px;
        background: $white;
        z-index: 1;
    }

    &__title {
        color: $ash-grey;
    }

    &__vendors {
        list-style-type: none;
        padding: 0;
        font-family: $font-default;
    }

    &__vendor {
        margin-bottom: 8px;
    }

    &__vendor-link {
        text-decoration: none;
        color: $picton-blue;
        font-weight: bold;
        display: inline-block;
        padding: 2px 4px;
        position: relative;
        border-radius: 4px;
        margin-right: 8px;

        &:after {
            content: '';
            width: 100%;
            height: 3px;
            bottom: 0;
            left: 0;
            position: absolute;
            background: $alice-blue;
            z-index: -1;
            transition: height $quick;
        }

        &:hover:after {
            height: 50%;
        }
    }

    &__vendor-location {
        color: $ash-grey;
        font-size: $font-md;
        font-weight: bold;
    }
}
