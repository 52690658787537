@import '_var.scss';

.root {
    display: flex;
    flex-direction: row;

    min-height: 100%;
    max-height: 100vh;
    width: 100%;
    transition: background 0.25s;

    &--blue {
        background: $maya-blue;
    }

    &--white {
        background: $white;
    }

    &--orange {
        background: $dark-orange;
    }

    &--black {
        background: $raisin-black;

        .form {
            background: $platinum;
        }
    }
}
