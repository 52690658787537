@import '_var.scss';

$block: '.option';

#{$block} {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    cursor: pointer;
    transition: transform $quick, background $quick;
    overflow: hidden;
    position: relative;
    border: none;
    font-family: inherit;
    background: none;
    outline: none;

    &__label {
        color: $dark-cerulean;
        margin-bottom: 8px;
        user-select: none;
        pointer-events: none;
        text-align: center;
        background: $white;
        padding: 2px 10px;
        border-radius: 40px;
        border: 1px solid rgba(0, 0, 0, 0.1);

        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__view {
        @include centred;
        z-index: -1;
        user-select: none;
        transition: transform $quick;

        &--icon {
            @include centred(scale(0.8));
        }

        &--color {
            @include spread-shadow;
            background: var(--color, red);
            height: 100%;
            width: 100%;
            border-radius: 4px;
        }

        &--length {
            font-size: $font-xxl;
            color: $dark-cerulean;
        }

        &--pattern {
            max-height: 100%;
        }
    }

    &--selected {
        @include spread-shadow;
        width: 142px;
        height: 142px;
        border-radius: 4px;
        background: $white;
        border: 2px solid $white;
        z-index: 0;
        transition: transform $quick, box-shadow $quick;

        #{$block}__label {
            font-size: $font-md;
        }

        &:hover,
        &#{$block}--active {
            transform: scale(1.1);

            box-shadow: 0 9px 8px rgba(0, 0, 0, 0.1),
                0 12px 12px rgba(0, 0, 0, 0.1), 0 24px 24px rgba(0, 0, 0, 0.1),
                0 72px 72px rgba(0, 0, 0, 0.2);

            #{$block}__view {
                @include centred(scale(1.2));
            }
        }
    }

    &--platter {
        width: 96px;
        height: 96px;
        padding: 10px;
        border-radius: 4px;
        overflow: visible;
        animation: fade-in $quick;

        &:hover {
            #{$block}__view {
                @include centred(scale(1.2));

                &--icon {
                    @include centred(scale(0.8));
                }
            }
        }

        &#{$block}--active:after {
            @include centred;
            content: '👍';
            top: 15%;
            font-size: $font-lg;
            color: black;
            animation: fade-in $quick;
        }

        #{$block} {
            &__label {
                font-size: $font-sm;
                white-space: nowrap;
                margin-bottom: -10px;
            }

            &__view {
                &--icon {
                    @include centred(scale(0.5));
                }

                &--color,
                &--pattern {
                    @include spread-shadow;
                    width: 64px;
                    height: 64px;
                    border-radius: 4px;
                }

                &--length {
                    font-size: $font-xl;
                }
            }
        }
    }

    &--info {
        width: 142px;
        height: 142px;
        border: 2px solid $off-white;
        border-radius: 4px;

        #{$block}__view--icon {
            @include centred(scale(1.2));
        }
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
