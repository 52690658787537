.bottom-section {
    position: absolute;
    bottom: 50px;
    width: 100%;

    >div {
        position: absolute;
        bottom: 0;
    }

    &__connector-b {
        left: 50%;
        transform: translate(-50%, 55px);
    }

    &__heatshrink {
        left: 50%;
        transform: translate(-50%, 0);
    }
}