@import url(https://fonts.googleapis.com/css?family=Staatliches&display=swap);
body {
  margin: 0;
  font-family: 'Staatliches', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html,
body {
  height: 100%;
  width: 100%; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.top-section {
  position: relative; }
  .top-section > div {
    position: absolute; }
  .top-section__connector-a {
    left: 50%;
    -webkit-transform: translate(-50%, 0) rotate(180deg);
            transform: translate(-50%, 0) rotate(180deg); }
  .top-section__heatshrink {
    left: 50%;
    -webkit-transform: translate(-50%, 55px) rotate(180deg);
            transform: translate(-50%, 55px) rotate(180deg);
    fill: red;
    fill: var(--fill-color, red); }
  .top-section__cable {
    left: 50%;
    -webkit-transform: translate(-50%, 150px);
            transform: translate(-50%, 150px);
    z-index: -1; }

.cable {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1); }
  .no-shadow .cable {
    box-shadow: none; }

.bottom-section {
  position: absolute;
  bottom: 50px;
  width: 100%; }
  .bottom-section > div {
    position: absolute;
    bottom: 0; }
  .bottom-section__connector-b {
    left: 50%;
    -webkit-transform: translate(-50%, 55px);
            transform: translate(-50%, 55px); }
  .bottom-section__heatshrink {
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }

.graphic {
  flex: 2 1;
  min-width: 12px;
  position: relative;
  height: calc(0.5 / 1 * 200vh);
  height: calc(0.5 / var(--scale, 1) * 200vh);
  overflow: hidden;
  position: relative;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform: scale(var(--scale, 1));
          transform: scale(var(--scale, 1));
  -webkit-transform-origin: center top;
          transform-origin: center top; }

.background-color-picker {
  display: flex; }
  .background-color-picker__swatch {
    width: 32px;
    height: 32px;
    border-radius: 200px;
    margin-right: 8px;
    position: relative;
    border: 2px solid transparent;
    outline: none;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.25s;
    transition: -webkit-transform 0.25s;
    transition: transform 0.25s;
    transition: transform 0.25s, -webkit-transform 0.25s; }
    .background-color-picker__swatch:hover {
      -webkit-transform: scale(0.8);
              transform: scale(0.8); }
  .background-color-picker--blue .background-color-picker__swatch--blue {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
    -webkit-transform: scale(1);
            transform: scale(1); }
    .no-shadow .background-color-picker--blue .background-color-picker__swatch--blue {
      box-shadow: none; }
  .background-color-picker--white .background-color-picker__swatch--white {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
    -webkit-transform: scale(1);
            transform: scale(1); }
    .no-shadow .background-color-picker--white .background-color-picker__swatch--white {
      box-shadow: none; }
  .background-color-picker--orange .background-color-picker__swatch--orange {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
    -webkit-transform: scale(1);
            transform: scale(1); }
    .no-shadow .background-color-picker--orange .background-color-picker__swatch--orange {
      box-shadow: none; }
  .background-color-picker--black .background-color-picker__swatch--black {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
    -webkit-transform: scale(1);
            transform: scale(1); }
    .no-shadow .background-color-picker--black .background-color-picker__swatch--black {
      box-shadow: none; }
  .background-color-picker__swatch--blue {
    background: #5adbff; }
  .background-color-picker__swatch--white {
    background: #ffffff;
    border: 2px solid #094074; }
  .background-color-picker__swatch--orange {
    background: #fe9000; }
  .background-color-picker__swatch--black {
    background: #242525; }

.contribute__button {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.05), 0 8px 16px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: #094074;
  background: #ffffff;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  -webkit-transition: box-shadow 0.25s;
  transition: box-shadow 0.25s;
  cursor: pointer;
  font-family: "Staatliches", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin: 4px 12px; }
  .no-shadow .contribute__button {
    box-shadow: none; }
  .contribute__button:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1); }
    .no-shadow .contribute__button:hover {
      box-shadow: none; }

@charset "UTF-8";
.option {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  cursor: pointer;
  -webkit-transition: background 0.25s, -webkit-transform 0.25s;
  transition: background 0.25s, -webkit-transform 0.25s;
  transition: transform 0.25s, background 0.25s;
  transition: transform 0.25s, background 0.25s, -webkit-transform 0.25s;
  overflow: hidden;
  position: relative;
  border: none;
  font-family: inherit;
  background: none;
  outline: none; }
  .option__label {
    color: #094074;
    margin-bottom: 8px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
    text-align: center;
    background: #ffffff;
    padding: 2px 10px;
    border-radius: 40px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis; }
  .option__view {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: -1;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-transition: -webkit-transform 0.25s;
    transition: -webkit-transform 0.25s;
    transition: transform 0.25s;
    transition: transform 0.25s, -webkit-transform 0.25s; }
    .option__view--icon {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%) scale(0.8);
              transform: translate(-50%, -50%) scale(0.8); }
    .option__view--color {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
      background: red;
      background: var(--color, red);
      height: 100%;
      width: 100%;
      border-radius: 4px; }
      .no-shadow .option__view--color {
        box-shadow: none; }
    .option__view--length {
      font-size: 64px;
      color: #094074; }
    .option__view--pattern {
      max-height: 100%; }
  .option--selected {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
    width: 142px;
    height: 142px;
    border-radius: 4px;
    background: #ffffff;
    border: 2px solid #ffffff;
    z-index: 0;
    -webkit-transition: box-shadow 0.25s, -webkit-transform 0.25s;
    transition: box-shadow 0.25s, -webkit-transform 0.25s;
    transition: transform 0.25s, box-shadow 0.25s;
    transition: transform 0.25s, box-shadow 0.25s, -webkit-transform 0.25s; }
    .no-shadow .option--selected {
      box-shadow: none; }
    .option--selected .option__label {
      font-size: 14px; }
    .option--selected:hover, .option--selected.option--active {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      box-shadow: 0 9px 8px rgba(0, 0, 0, 0.1), 0 12px 12px rgba(0, 0, 0, 0.1), 0 24px 24px rgba(0, 0, 0, 0.1), 0 72px 72px rgba(0, 0, 0, 0.2); }
      .option--selected:hover .option__view, .option--selected.option--active .option__view {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%) scale(1.2);
                transform: translate(-50%, -50%) scale(1.2); }
  .option--platter {
    width: 96px;
    height: 96px;
    padding: 10px;
    border-radius: 4px;
    overflow: visible;
    -webkit-animation: fade-in 0.25s;
            animation: fade-in 0.25s; }
    .option--platter:hover .option__view {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%) scale(1.2);
              transform: translate(-50%, -50%) scale(1.2); }
      .option--platter:hover .option__view--icon {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%) scale(0.8);
                transform: translate(-50%, -50%) scale(0.8); }
    .option--platter.option--active:after {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      content: '👍';
      top: 15%;
      font-size: 24px;
      color: black;
      -webkit-animation: fade-in 0.25s;
              animation: fade-in 0.25s; }
    .option--platter .option__label {
      font-size: 12px;
      white-space: nowrap;
      margin-bottom: -10px; }
    .option--platter .option__view--icon {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%) scale(0.5);
              transform: translate(-50%, -50%) scale(0.5); }
    .option--platter .option__view--color, .option--platter .option__view--pattern {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
      width: 64px;
      height: 64px;
      border-radius: 4px; }
      .no-shadow .option--platter .option__view--color, .no-shadow .option--platter .option__view--pattern {
        box-shadow: none; }
    .option--platter .option__view--length {
      font-size: 32px; }
  .option--info {
    width: 142px;
    height: 142px;
    border: 2px solid #fafafa;
    border-radius: 4px; }
    .option--info .option__view--icon {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%) scale(1.2);
              transform: translate(-50%, -50%) scale(1.2); }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.lazy-option {
  width: 96px;
  height: 96px;
  position: relative; }
  .lazy-option:before {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    content: '';
    border-radius: 200px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 24px;
    height: 24px;
    margin: 0;
    opacity: 0; }
  .lazy-option--loading:before {
    opacity: 1; }

.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  max-height: 500px;
  width: 90%;
  height: auto;
  border-radius: 4px;
  z-index: 4;
  background: #ffffff;
  -webkit-animation: grow-in 0.25s;
          animation: grow-in 0.25s;
  overflow: hidden;
  display: flex;
  flex-direction: column; }
  .no-shadow .modal {
    box-shadow: none; }
  .modal--exiting {
    -webkit-animation: shrink-out 0.25s forwards;
            animation: shrink-out 0.25s forwards;
    pointer-events: none; }
  .modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fafafa;
    padding: 8px;
    padding-left: 32px; }
  .modal__title {
    color: #094074;
    text-align: center;
    width: 100%; }
  .modal__close-button {
    border: none;
    background: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 4px;
    width: 28px;
    height: 28px;
    outline: none;
    float: right; }
    .modal__close-button > svg {
      -webkit-transform: scale(0.6);
              transform: scale(0.6); }
    .modal__close-button:hover {
      background: #ffffff; }
  .modal__body {
    overflow: auto;
    padding: 8px;
    padding-bottom: 24px; }

.modal-backdrop {
  z-index: 3;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: fade-in 0.25s;
          animation: fade-in 0.25s; }
  .modal-backdrop--exiting {
    -webkit-animation: fade-out 0.25s forwards;
            animation: fade-out 0.25s forwards;
    pointer-events: none; }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes grow-in {
  0% {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0.8);
            transform: translate(-50%, -50%) scale(0.8);
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 1; } }

@keyframes grow-in {
  0% {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0.8);
            transform: translate(-50%, -50%) scale(0.8);
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 1; } }

@-webkit-keyframes shrink-out {
  0% {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0.8);
            transform: translate(-50%, -50%) scale(0.8);
    opacity: 0; } }

@keyframes shrink-out {
  0% {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0.8);
            transform: translate(-50%, -50%) scale(0.8);
    opacity: 0; } }

.part-info-modal__more-icon {
  border-radius: 4px;
  height: 24px;
  width: 24px;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: background 0.25s;
  transition: background 0.25s;
  cursor: pointer;
  outline: none; }
  .part-info-modal__more-icon:hover {
    background: #fafafa; }

.part-info-modal__body {
  display: flex;
  -webkit-user-select: initial;
     -moz-user-select: initial;
      -ms-user-select: initial;
          user-select: initial; }

.part-info-modal__left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  pointer-events: none;
  padding-right: 8px; }

.part-info-modal__value {
  font-family: "Hack", "Menlo", monospace;
  font-size: 12px;
  text-align: center;
  border: 2px solid #fafafa;
  padding: 4px 8px;
  border-radius: 4px;
  margin-top: 24px;
  pointer-events: initial;
  background: #ffffff;
  z-index: 1; }

.part-info-modal__right {
  padding-left: 16px;
  background: #ffffff;
  z-index: 1; }

.part-info-modal__title {
  color: #a9b8c0; }

.part-info-modal__vendors {
  list-style-type: none;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

.part-info-modal__vendor {
  margin-bottom: 8px; }

.part-info-modal__vendor-link {
  text-decoration: none;
  color: #4aa8ff;
  font-weight: bold;
  display: inline-block;
  padding: 2px 4px;
  position: relative;
  border-radius: 4px;
  margin-right: 8px; }
  .part-info-modal__vendor-link:after {
    content: '';
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    position: absolute;
    background: #e9f3fc;
    z-index: -1;
    -webkit-transition: height 0.25s;
    transition: height 0.25s; }
  .part-info-modal__vendor-link:hover:after {
    height: 50%; }

.part-info-modal__vendor-location {
  color: #a9b8c0;
  font-size: 14px;
  font-weight: bold; }

.platter {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 0 auto; }

.picker {
  display: flex;
  flex-direction: column;
  padding: 8px; }
  .picker__header {
    margin: 8px;
    margin-right: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .picker__part-name {
    color: #094074; }

.form {
  flex: 3 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  background: rgba(255, 255, 255, 0.2);
  -webkit-transition: background 0.25s;
  transition: background 0.25s; }
  .form__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #ffffff;
    padding: 16px;
    box-sizing: border-box; }
  .form__header-right {
    display: flex;
    align-items: center; }
  .form__title {
    font-size: 24px; }
  .form__content {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    max-width: 600px;
    margin: 0 auto;
    flex: 1 1;
    padding: 16px; }

.root {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  max-height: 100vh;
  width: 100%;
  -webkit-transition: background 0.25s;
  transition: background 0.25s; }
  .root--blue {
    background: #5adbff; }
  .root--white {
    background: #ffffff; }
  .root--orange {
    background: #fe9000; }
  .root--black {
    background: #242525; }
    .root--black .form {
      background: #e3e5ec; }

