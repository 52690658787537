@import '_var.scss';

.modal {
    @include centred;
    @include spread-shadow;

    max-width: 600px;
    max-height: 500px;
    width: 90%;
    height: auto;
    border-radius: 4px;
    z-index: 4;
    background: $white;
    animation: grow-in $quick;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    &--exiting {
        animation: shrink-out $quick forwards;
        pointer-events: none;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $off-white;
        padding: 8px;
        padding-left: 32px;
    }

    &__title {
        color: $dark-cerulean;
        text-align: center;
        width: 100%;
    }

    &__close-button {
        border: none;
        background: none;
        cursor: pointer;
        border-radius: 4px;
        padding: 4px;
        width: 28px;
        height: 28px;
        outline: none;
        float: right;

        > svg {
            transform: scale(0.6);
        }

        &:hover {
            background: $white;
        }
    }

    &__body {
        overflow: auto;
        padding: 8px;
        padding-bottom: 24px;
    }
}

.modal-backdrop {
    z-index: 3;
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: fade-in $quick;

    &--exiting {
        animation: fade-out $quick forwards;
        pointer-events: none;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes grow-in {
    0% {
        @include centred(scale(0.8));
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        @include centred(scale(1));
        opacity: 1;
    }
}

@keyframes shrink-out {
    0% {
        @include centred(scale(1));
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        @include centred(scale(0.8));
        opacity: 0;
    }
}
