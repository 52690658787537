@import '_var.scss';

$block: '.background-color-picker';
$colors: 'blue', 'white', 'orange', 'black';

#{$block} {
    display: flex;

    &__swatch {
        width: 32px;
        height: 32px;
        border-radius: 200px;
        margin-right: 8px;
        position: relative;
        border: 2px solid transparent;
        outline: none;
        transform: scale(0.6);
        cursor: pointer;
        transition: transform $quick;

        &:hover {
            transform: scale(0.8);
        }
    }

    @each $color in $colors {
        &--#{$color} #{$block}__swatch--#{$color} {
            @include spread-shadow;
            transform: scale(1);
        }
    }

    &__swatch--blue {
        background: $maya-blue;
    }

    &__swatch--white {
        background: $white;
        border: 2px solid $dark-cerulean;
    }

    &__swatch--orange {
        background: $dark-orange;
    }

    &__swatch--black {
        background: $raisin-black;
    }
}
