@import '_var.scss';

.lazy-option {
    width: 96px;
    height: 96px;
    position: relative;

    &:before {
        @include centred;
        content: '';
        border-radius: 200px;
        border: 4px solid rgba(0, 0, 0, 0.1);
        width: 24px;
        height: 24px;
        margin: 0;
        opacity: 0;
    }

    &--loading:before {
        opacity: 1;
    }
}
