// colors

$white: #ffffff;
$off-white: #fafafa;

$alice-blue: #e9f3fc;
$maya-blue: #5adbff;
$picton-blue: #4aa8ff;
$yale-blue: #125594;
$dark-orange: #fe9000;
$raisin-black: #242525;
$dark-cerulean: #094074;
$platinum: #e3e5ec;
$ash-grey: #a9b8c0;

// fonts

$font-default: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
$font-main: 'Staatliches', $font-default;
$font-mono: 'Hack', 'Menlo', monospace;

$font-sm: 12px;
$font-md: 14px;
$font-lg: 24px;
$font-xl: 32px;
$font-xxl: 64px;

// transitions

$quick: 0.25s;

// shadows

@mixin spread-shadow($color: rgba(0, 0, 0, 0.1)) {
    box-shadow: 0 1px 1px $color, 0 2px 4px $color, 0 4px 8px $color,
        0 8px 16px $color;

    .no-shadow & {
        box-shadow: none;
    }
}

// positioning

@mixin centred($transform: 0) {
    position: absolute;
    left: 50%;
    top: 50%;
    @if $transform != 0 {
        transform: translate(-50%, -50%) $transform;
    } @else {
        transform: translate(-50%, -50%);
    }
}

@mixin centred-x($transform: 0) {
    position: absolute;
    left: 50%;
    @if $transform != 0 {
        transform: translateX(-50%) $transform;
    } @else {
        transform: translateX(-50%);
    }
}

// breakpoints

$bp-small: 400px;
$bp-medium: 1200px;

@mixin small {
    @media (max-width: $bp-small) {
        @content;
    }
}

@mixin medium {
    @media (min-width: $bp-small) and (max-width: $bp-medium) {
        @content;
    }
}

@mixin medium {
    @media (min-width: $bp-medium) {
        @content;
    }
}
