@import '_var.scss';

.contribute {
    &__button {
        @include spread-shadow(rgba(0, 0, 0, 0.05));
        text-decoration: none;
        color: $dark-cerulean;
        background: $white;
        border-radius: 4px;
        padding: 4px 8px;
        font-size: $font-md;
        transition: box-shadow $quick;
        cursor: pointer;
        font-family: $font-main;
        margin: 4px 12px;

        &:hover {
            @include spread-shadow;
        }
    }
}
